import React from 'react';
import { useEffect, createContext, useContext, useReducer } from 'react'

import { POST } from '../services/request';
export const Types = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
}
const version = 'v1.6.0';
let user = null;
let token = null;
if (typeof window !== 'undefined') {
  const currentVersion = localStorage?.getItem('version');
  if (version !== currentVersion) {
    localStorage?.clear();
    localStorage?.setItem('version', version);
  }

  user = localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user')) : null;
  token = localStorage?.getItem('token');
}

const initialState = {
  user,
  token
}


const CountStateContext = createContext({
  state: initialState,
  dispatch: () => null
});

function cartReducer(state, action) {
  switch (action.type) {
    case Types.LOGIN: {
      const { token, user } = action.item
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('token', token)
      return {
        token, user
      }
    }
    case Types.LOGOUT: {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      return null
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: { user, token } } = await POST('/auths/signin-by-token', {
          token: state.token
        }, {}, false);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
      } catch (error) {
        localStorage.removeItem('user')
      }
    }

    if (state && state.token && state.user) {
      fetchUser();
    }
    else if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
    }
  }, [state])
  return (
    <CountStateContext.Provider value={{ state, dispatch }}>
      {children}
    </CountStateContext.Provider>
  )
}

export const useUser = () => useContext(CountStateContext)