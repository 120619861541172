import React from 'react';
import { useSidebarToggle } from '../../context/sidebarToggle';
import { useState } from 'react';
import dynamic from 'next/dynamic';

const ShareSocial = dynamic(() => import('../elements/share-social'), {
  ssr: true,
});

const Footer = () => {
  const { dispatch: dispatchSidebarToggle } = useSidebarToggle();
  const [toggle, setToggle] = useState(false);
  
  return (
    <>
      <div className="footer-nav-area" id="footerNav">
        <ShareSocial toggle={toggle} setToggle={() => setToggle(!toggle)}/>
        <div className="container h-100 px-0">
          <div className="suha-footer-nav h-100">
            <ul className="h-100 d-flex align-items-center justify-content-between ps-0 animate__animated animate__backInUp">
              <li className="active animate__animated animate__bounce"><a href={'tel:0903760903'} className="text-orange"><i className="fa fa-phone"></i>Gọi ngay</a></li>
              <li><a href={'https://zalo.me/0903760903'} className="text-orange"><i className="fa fa-comments-o"></i>Nhắn Zalo</a></li>
              <li><span onClick={() => setToggle(true)} className="text-orange"><i className="fa fa-share-alt"></i>Chia sẻ</span></li>
              <li><span className="text-orange" onClick={() => {
                dispatchSidebarToggle({
                  type: true
                })
              }}><i className="fa fa-bars"></i>Menu</span></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer