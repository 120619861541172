import '../styles/globals.css';
import '../styles/template/style.scss';
import { UserProvider } from '../context/user';
import { CartProvider } from '../context/cart';
import { SidebarToggleProvider } from '../context/sidebarToggle';
import { ToastProvider } from 'react-toast-notifications'
import Layout from '../components/layouts';
import Head from 'next/head';

function MyApp({ Component, pageProps }) {
  return <>
    <Head />
    <ToastProvider autoDismiss autoDismissTimeout={3000}>
      <SidebarToggleProvider>
        <UserProvider>
          <CartProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </CartProvider>
        </UserProvider>
      </SidebarToggleProvider>
    </ToastProvider>
  </>
}

export default MyApp
