import React from 'react';
import Header from './Header'
import Footer from './Footer'
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

const Sidebar = dynamic(() => import('./Sidebar'), {
  ssr: true,
});
const Layout = ({ children}) => {
  const { pathname } = useRouter()
  return (
    <>
      <Header/>
      <Sidebar/>
      {children}
      {!pathname.startsWith('/products/') && <Footer/> }
    </>
  )
}

export default Layout