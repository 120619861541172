import React from 'react'

const initialState = ({
  isOpenMenu: false
});
const SidebarToggleStateContext = React.createContext({
  state: initialState,
  dispatch: () => null
});
function sidebarToggleReducer(state, action) {
  return { isOpenMenu: !!action.type }
}
export const SidebarToggleProvider = ({children})  => {
  const [state, dispatch] = React.useReducer(sidebarToggleReducer, initialState);
  return (
    <SidebarToggleStateContext.Provider value={{state, dispatch}}>
      {children}
    </SidebarToggleStateContext.Provider>
  )
}
export const useSidebarToggle = () => React.useContext(SidebarToggleStateContext)