import React from 'react';
import { calcDiscount } from '../utils';
export const Types = {
  Update: 'UPDATE_PRODUCT',
  Delete: 'DELETE_PRODUCT',
  DeleteInvalid: 'DELETE_PRODUCT_INVALID',
  BuyAgain: 'BUY_AGAIN',
  Add: 'ADD_PRODUCT',
}
const initialState = typeof window !== 'undefined' ? ({ items: JSON.parse(localStorage?.getItem('cart') || '[]') }) : { items: [] };
const CountStateContext = React.createContext({
  state: initialState,
  dispatch: () => null
});
function cartReducer(state, action) {
  switch (action.type) {
    case Types.Add: {
      const isExist = state.items.filter(item => item.id === action.item.id && item.attribute === action.item.attribute).length > 0;
      const items = isExist ?
        state.items.map(item => {
          const newQuantity = item.quantity + ((item.id === action.item.id && item.attribute === action.item.attribute) ? action.item.quantity : 0)
          return {
            ...item,
            quantity: newQuantity,
            discountPerQuantity: calcDiscount(newQuantity, item.discounts || [])
          }
        })
        : state.items.concat(action.item)
      localStorage.setItem('cart', JSON.stringify(items))
      return { items }
    }
    case Types.Update: {
      const items = state.items.map(item => (item.id === action.item.id && item.attribute === action.item.attribute) ? action.item : item)
      localStorage.setItem('cart', JSON.stringify(items))
      return { items }
    }
    case Types.Delete: {
      const items = state.items.filter(item => !(item.id === action.item.id && item.attribute === action.item.attribute))
      localStorage.setItem('cart', JSON.stringify(items))
      return { items }
    }
    case Types.DeleteInvalid: {
      const items = state.items.filter(item => !action.item.includes(item.attribute));
      localStorage.setItem('cart', JSON.stringify(items))
      return { items }
    }
    case Types.BuyAgain: {
      localStorage.setItem('cart', JSON.stringify(action.item));
      return {
        items: action.item
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
export const CartProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(cartReducer, initialState);
  return (
    <CountStateContext.Provider value={{ state, dispatch }}>
      {children}
    </CountStateContext.Provider>
  )
}
export const useCart = () => React.useContext(CountStateContext)