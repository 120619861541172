import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useCart } from '../../context/cart';
import { GET, renderTokenSource } from '../../services/request';
import Router from 'next/router';
import Image from 'next/image';
import defaultProductImg from "../../assets/images/defaultProduct.jpg";
const Header = ({ initSearch }) => {
  const source = useRef(null);
  const searchRef = useRef(null);
  const timeoutSearch = useRef(null);
  const { state: stateCart } = useCart();
  const [styleAnimation, setAnimation] = useState({
    shake: false
  });
  const [search, setSearch] = useState(initSearch);
  const [suggestions, setShowSuggestions] = useState([]);

  useEffect(() => {
    if (stateCart.items.length > 0) {
      setAnimation({
        shake: true
      })
      setTimeout(() => {
        setAnimation({
          shake: false
        })
      }, 500);
    }

  }, [stateCart])

  useEffect(() => {
    if (search !== '') {
      clearTimeout(timeoutSearch.current);
      timeoutSearch.current = setTimeout(() => {
        if (source.current !== null) {
          source.current.cancel();
        }
        source.current = renderTokenSource();
        GET(`/products/search?search=${search}`, {
          cancelToken: source.current.token,
          params: {
            limit: 5,
            offset: 0
          }
        })
          .then((products) => {
            setShowSuggestions(products.rows)
          })
          .catch(err => { })
      }, 300);
    }
    else {
      setShowSuggestions([])
    }
  }, [search])

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (source.current !== null) {
      source.current.cancel();
    }
    setTimeout(() => {
      setShowSuggestions([]);
    }, 500);
    searchRef.current.blur();
    Router.push(`/search?search=${search}`)
  }
  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  const onBlur = () => {
    if (source.current !== null) {
      source.current.cancel();
    }
    setTimeout(() => {
      setShowSuggestions([]);
    }, 500);
  }

  const totalProductOncart = () => {
    return stateCart.items.reduce((prev, next) => prev + (next.quantity || 0), 0)
  }

  return (
    <>
      <div className="header-area" id="headerArea">
        <div className="container h-100 d-flex align-items-center justify-content-between">
          <div className="logo-wrapper">
            <div onClick={() => Router.push('/')}>
              TÂN TIẾN
            </div>
          </div>
          <div className="top-search-form search-button-header">
            <form onSubmit={onSubmitSearch}>
              <input
                ref={searchRef}
                value={search}
                onChange={onSearch}
                onBlur={onBlur}
                className="form-control"
                type="text"
                placeholder="Tìm sản phẩm" />
              <button aria-label="tìm kiếm" type="submit" disabled={search ? false : true} ><i className="fa fa-search"></i></button>
            </form>
          </div>
          <div className={`d-flex flex-wrap cart-header ${styleAnimation.shake ? 'shake' : ''} cursor-pointer`}
            onClick={() => Router.push('/cart')}>
            {stateCart.items.length > 0 ? <span className="badge-header">{totalProductOncart()}</span> : null}
            <i className={`
              fa fa-shopping-bag text-custom-primary
            `}></i>
          </div>
        </div>
        {(search !== '' && suggestions.length > 0) ?
          <div className="container p-0">
            <div className="search-suggestion bg-white border">
              {suggestions.map((item, key) =>
                <div key={key} className="d-flex p-1 flex-row sidebar-link" onClick={() => Router.push(`/products/${item.slug}`)
                }>
                  <div style={{ height: '40px', width: '40px', position: 'static' }}>
                    <Image width={40} height={40} loading={"lazy"} alt={item.name} src={item.picture?.filter((pic) => pic.type === 'image')?.[0]?.url || defaultProductImg} />
                  </div>
                  <div className="px-2 title flex-column d-flex search-info">
                    <strong>{item.name}</strong>
                    <div className="info-category">
                      Danh mục:
                      <span className="category">{` ${item.Category.name}`}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          : null}
      </div>
    </>
  )
}


export async function getServerSideProps({ query: { search } }) {
  return {
    props: {
      search: search || ''
    }
  }
}

export default Header